import { useCallback, useEffect, useState } from "react";
import { User } from "@sumit-platforms/types";
import { useUser } from "../store/user";
import * as Sentry from "@sentry/react";
import axios from "axios";

export const useAuth = ({
  config,
  getUserDetails,
}: {
  config: any;
  getUserDetails?: () => Promise<User>;
}): {
  user: User | null;
  setUser: (user: User | null) => void;
  roles: any;
  isLoading: boolean;
  signIn: typeof signIn;
  // signUp: typeof signUp;
  signOut: typeof signOut;
  handleUserAuthentication: typeof handleUserAuthentication;
} => {
  const authUrl = `${config.server.host}/${config.server.auth}`;

  const { user, roles, setUser } = useUser();

  const [loading, setLoading] = useState(!user);
  useEffect(() => {
    setLoading(!!user);
    handleUserAuthentication();
  }, [user]);

  const handleUserAuthentication = useCallback(async () => {
    try {
      if (user || !getUserDetails) return;
      setLoading(true);
      let loggedInUser = null;

      const userDetails: any = await getUserDetails();
      if (userDetails) {
        loggedInUser = {
          ...userDetails,
        };
      }
      setUser(loggedInUser as User);
      Sentry.setUser({
        uid: userDetails.idUser,
        name: `${userDetails.firstName} ${userDetails.lastName}`,
        email: userDetails.email || "",
      });
    } catch (err) {
      console.error(err);
      // setUser(null);
      // LocalStorageService.clearAll();
      // signOut();
    } finally {
      setLoading(false);
    }
  }, [getUserDetails, setUser, user]);

  const signIn = async (callbackPath: string | null) => {
    return axios
      .get(
        `${authUrl}/login?callbackPath=${callbackPath || window.location.href}`
      )
      .then((res) => {
        const redirectionUrl = res.data.redirectUrl;
        if (redirectionUrl) {
          window.location.href = redirectionUrl;
        }
      });
  };
  const signOut = async () => {
    return axios
      .get(`${authUrl}/logout?callbackPath=${window.location}`, {
        withCredentials: true,
      })
      .then((res) => {
        const redirectionUrl = res.data.redirectUrl;
        if (redirectionUrl) {
          window.location.href = redirectionUrl;
        }
      });
  };

  return {
    user,
    setUser,
    roles,
    signIn,
    // signUp,
    signOut,
    handleUserAuthentication,
    isLoading: loading,
  };
};
