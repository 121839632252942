export default {
  port: 3000,
  appName: "must",
  server: {
    host: "", // Server's host
    auth: "auth",
    mfa: "auth/mfa",
    users: "users",
    jobs: "jobs",
    attachments: "attachments",
    uploads: "uploads",
    tags: "tags",
    speakers: "speakers",
    files: "files",
    export: "export",
    search: "search",
    extract: "extract",
    projects: "projects",
  },
  firebase: {}, // Firebase config
  featureFlags: {
    host: "https://eu.app.unleash-hosted.com/euee0010/api/frontend",
    apiKey:
      "must:production.8d0031d76c045c13356d89e0385b72bfc6c129cb20b1daa5bbe783ca",
    jobContextSearch: true,
    autoTranscript: {
      clients: [
        1, 210, 443, 486, 573, 875, 901, 910, 913, 756, 915, 917, 919, 425, 921,
        933, 934,
      ],
      enabled: true,
    },
    transcriptEditor: {
      enabled: true,
    },
    appName: "must",
  },
  delivery: {
    minDeliveryDays: 4,
    minAutoTranscriptDeliveryHours: 12,
  },
  sentry: {
    dsn: "https://00b6847fd73aeeeb671aa34f7b670377@o689365.ingest.sentry.io/4505782613639168",
  },
  versionHistory: "https://app.clickup.com/5709552/v/dc/5e7qg-13875",
};
