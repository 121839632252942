import axios, { InternalAxiosRequestConfig } from "axios";

axios.interceptors.request.use(
  async (
    request: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    request.withCredentials = true;
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   async (response: AxiosResponse<APIErrorResult>) => {
//     if (!response.data.success || response.status !== 200) {
//       const message = response.data.message;
//       throw new Error(message);
//     }
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
