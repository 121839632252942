import React from "react";
import { RenderElementProps } from "@sumit-platforms/slate-react";
import RangeSpeaker from "../Ranges/RangeSpeaker";
import {
  AnnotationRangeElement,
  CustomElement,
  SpeakerRangeElement,
} from "../../types";
import RangeAnnotation from "../RangeAnnotation/RangeAnnotation";
import { UpdateTcOffsetFn } from "../../editor";
import RangeSubtitles from "../Ranges/RangeSubtitles";
import RangeMedia from "../Ranges/RangeMedia";
import RangeSubtitlesPlaceholder from "../Ranges/RangeSubtitlesPlaceholder";
import { EditorForwardedRef } from "../../SlateEditor";

type InternalProps = {
  updateTcOffset: UpdateTcOffsetFn;
  hideTimecode?: boolean;
  handleBlur?: (rangeIndex?: number) => void;
  startIndex?: number | null;
  renderIndex?: number | null;
  isMediaRange?: boolean;
  isPlaceholder?: boolean;
  isElementSelected?: boolean;
  editorElement?: HTMLElement | null;
};
const Range = ({
  attributes,
  children,
  element,
  updateTcOffset,
  hideTimecode,
  handleBlur,
  startIndex,
  renderIndex,
  isMediaRange,
  isPlaceholder,
  isElementSelected,
  editorElement,
}: RenderElementProps & InternalProps) => {
  const e = element as CustomElement;
  switch (e.type) {
    case "speakersRange": {
      const ele = element as SpeakerRangeElement;
      return (
        <RangeSpeaker
          attributes={attributes}
          element={ele}
          hideTimecode={hideTimecode}
          updateTcOffset={updateTcOffset}
          handleBlur={handleBlur}
          editorElement={editorElement}
        >
          {children}
        </RangeSpeaker>
      );
    }
    case "annotationRange": {
      const ele = element as AnnotationRangeElement;
      return (
        <RangeAnnotation element={ele} annotation={ele.annotation}>
          {children}
        </RangeAnnotation>
      );
    }
    case "subtitleRange": {
      return isMediaRange ? (
        <RangeMedia
          attributes={attributes}
          renderIndex={renderIndex}
          handleBlur={handleBlur}
          startIndex={startIndex}
          isPlaceholder={isPlaceholder}
          isElementSelected={isElementSelected}
        >
          {children}
        </RangeMedia>
      ) : (
        <RangeSubtitles
          attributes={attributes}
          handleBlur={handleBlur}
          startIndex={startIndex}
          renderIndex={renderIndex}
          isElementSelected={isElementSelected}
        >
          {children}
        </RangeSubtitles>
      );
    }
    case "subtitlePlaceholder": {
      return isMediaRange ? (
        <RangeMedia
          attributes={attributes}
          renderIndex={renderIndex}
          handleBlur={handleBlur}
          startIndex={startIndex}
          isPlaceholder={true}
        />
      ) : (
        <RangeSubtitlesPlaceholder
          attributes={attributes}
          startIndex={startIndex}
          renderIndex={renderIndex}
          hide={renderIndex === 2}
        >
          {children}
        </RangeSubtitlesPlaceholder>
      );
    }

    default:
      return <p {...attributes}>{e.type}</p>;
  }
};

export default Range;
