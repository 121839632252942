import React, { useCallback, useMemo } from "react";
import DiffRange from "../DiffRange/DiffRange";
import { ContentScrollProps } from "../../SlateEditor";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { CustomEditor, CustomElement, SubtitleRangeElement } from "../../types";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";
import classNames from "classnames";
import { getLanguageDirection } from "@sumit-platforms/ui-bazar/utils";
import { Languages, SubtitlesRange } from "@sumit-platforms/types";
import { isBetweenRangesState, startIndexState } from "../../store/states";

interface SourceWordsRollupProps {
  height: number;
  contentScroll: ContentScrollProps;
  inputLanguage?: Languages | null;
}

const SourceWordsRollup = ({
  height,
  contentScroll,
  inputLanguage,
}: SourceWordsRollupProps) => {
  const editor = useSlateStatic() as CustomEditor;
  const startIndex = useRecoilValue(startIndexState);
  const isBetweenRanges = useRecoilValue(isBetweenRangesState);

  const dir = useMemo(() => {
    if (!inputLanguage) return "ltr";
    return getLanguageDirection(inputLanguage);
  }, [inputLanguage]);

  const { subtitleHeight, mediaHeight } = useMemo(
    () => contentScroll,
    [contentScroll]
  );

  const getRange = useCallback(
    (index: number) => {
      const child = editor.children[index] as CustomElement;
      if (child.type === "subtitlePlaceholder") return;

      return (child as SubtitleRangeElement)?.range;
    },
    [editor.children]
  );

  const firstRange = useMemo(() => {
    const index = (startIndex || 0) + (isBetweenRanges ? 1 : 0);
    const range = getRange(index);
    return range;
  }, [isBetweenRanges, startIndex, getRange]);

  const secondRange = useMemo(() => {
    const index = (startIndex || 0) + 1;
    const range = getRange(index);
    return range;
  }, [startIndex, getRange]);

  const thirdRange = useMemo(() => {
    const index = (startIndex || 0) + 2;
    const range = getRange(index);
    return range;
  }, [startIndex, getRange]);

  const getTranslationString = useCallback((range?: SubtitlesRange) => {
    return range?.sourceWords?.map((w) => w.word)?.join(" ") || "";
  }, []);

  return (
    <Box
      className={classNames(`EditorRollup ${dir}`)}
      style={{ height: `${height}px` }}
      dir={dir}
    >
      <DiffRange height={subtitleHeight}>
        {getTranslationString(firstRange)}
      </DiffRange>
      <DiffRange height={mediaHeight} className={"middleRange"}>
        {isBetweenRanges ? "" : getTranslationString(secondRange)}
      </DiffRange>
      <DiffRange height={subtitleHeight}>
        {getTranslationString(thirdRange)}
      </DiffRange>
    </Box>
  );
};

export default SourceWordsRollup;
