import config from "../../config";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { faAdd, faArrowDownToLine } from "@fortawesome/pro-light-svg-icons";

import {
  GeneralTable,
  HeadCell,
  SearchInput,
  TableContext,
  TagsModal,
  grayColor4,
} from "@sumit-platforms/ui-bazar";
import { ExportedFile, Job, Tag, jobTypes } from "@sumit-platforms/types";

import useSpeakers from "../../hooks/useSpeakers";

import * as searchService from "../../services/searchService";

import { ExtractFilters } from "./components/ExtractFilters";
import { ExtractExportModal } from "../../components/modals/ExtractExportModal/ExtractExportModal";
import { useAuth, useTags } from "@sumit-platforms/ui-bazar/hooks";
import tagService from "../../services/tagService";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import {
  getLanguageDirection,
  getShortArrayString,
  secondsToTimeString,
} from "@sumit-platforms/ui-bazar/utils";
import { exportService as ExportService } from "@sumit-platforms/ui-bazar/services";
import jobService from "../../services/jobService";
import { clientStore } from "../../store/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Extract.scss";

const exportService = ExportService({ config });

export interface JobRow {
  id: number;
  name: string;
  lang: string;
  tags: Tag[];
  tc: number;
  speakers: string[];
  speaker: string;
  transcript: { speaker: string; text: string }[];
  contextMenu: any;
  elasticJob: any;
  dbJob: Job;
}

export const EXTRACT_PAGE_DATE_FORMAT = "dd/MM/yyyy";

const Extract = () => {
  const { t } = useTranslation();
  const { user } = useAuth({ config });
  const { tags } = useTags({ entity: "job", tagService });

  const { client } = clientStore();
  const { speakers } = useSpeakers({
    user,
  });
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const [loadingJobs, setLoadingJobs] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [filters, setFilters] = useState<any>({});
  const [searchResults, setSearchResults] = useState<JobRow[]>([]);
  const [resultsTableData, setResultsTableData] = useState<JobRow[]>([]);
  const [selected, setStelected] = useState<number[]>([]);
  const selectedRows = useMemo(() => {
    return searchResults.filter((j: JobRow) => selected.includes(j.id));
  }, [selected, searchResults]);
  const [isCreatingDocx, setIsCreatingDocx] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState("");

  const queryOffset = useRef<number>(0);
  const queryLimit = useRef<number>(25);

  const handleExportExtract = async (fileName: string, singleResult?: any) => {
    try {
      const searchParams = {
        search: [searchQuery],
      };
      setIsCreatingDocx(true);
      const docx = await exportService.createExtract(
        singleResult || selectedRows,
        searchParams
      );
      const filesToExport: ExportedFile[] = [
        { filename: `${fileName}.docx`, data: docx[0] },
      ];
      await exportService.handleExportedFiles(filesToExport);
      closeModal();
      setIsCreatingDocx(false);
    } catch (err) {
      setIsCreatingDocx(false);
    }
  };

  useEffect(() => {
    if (!client) return;
    setHasMore(true);
    if (searchQuery.length === 0) setHasMore(false);
    if (selected.length > 0) {
      setStelected([]);
    }
    debouncedSearch(searchQuery, filters, client);
  }, [searchQuery, filters, client]);

  const debouncedSearch = React.useRef(
    _.debounce(async (_query, _filters, client) => {
      if (!client) return;
      setLoadingJobs(true);
      searchService
        .queryExtractIndex({
          query: _query,
          filters: _filters,
          page: 0,
          size: queryLimit.current,
          idClient: client?.idClient,
        })
        //TODO: set properly has more. curretly the hasmore state is not correct !!!
        .then((searchHits) => {
          setSearchResults(searchHits);
        })
        .finally(() => setLoadingJobs(false));
    }, 300)
  ).current;

  const setTags = async (idJob: number[], tags: Tag[]): Promise<void> => {
    try {
      await jobService.setTags(
        idJob,
        tags.map((t) => t.idTag)
      );
      setResultsTableData((prev) =>
        prev.map((j) => (idJob.includes(j.dbJob.idJob) ? { ...j, tags } : j))
      );
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const openTagsModal = (rows: JobRow[], tags: Tag[]): void => {
    if (client)
      setModalContent(
        <TagsModal
          entity="job"
          submit={(_tags) =>
            setTags(
              rows.map((r) => r.elasticJob.id_job),
              _tags
            )
          }
          cancel={closeModal}
          tags={tags}
          idClient={client?.idClient}
          tagService={tagService}
        />
      );
  };

  const loadOnScroll = async (offset: number) => {
    if (!client) return;
    setLoadingJobs(true);
    queryOffset.current = offset;
    const results = await searchService.queryExtractIndex({
      query: searchQuery,
      filters,
      page: queryOffset.current,
      size: queryLimit.current,
      idClient: client?.idClient,
    });
    if (results.length > 0) {
      setSearchResults((prev) => [...prev, ...results]);
    } else {
      setHasMore(false);
    }
    setLoadingJobs(false);
  };

  useEffect(() => {
    const _tableData = searchResults.map((job) => {
      return createJobRow(job);
    });

    setResultsTableData(_tableData);
  }, [searchResults]);

  const renderRowContext = (row: JobRow) => {
    return (
      <TableContext
        context={[
          {
            name: t("export_job"),
            action: () => openExportJobModal([row.elasticJob]),
          },
        ]}
        row={row}
      />
    );
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openExportJobModal = (singleResult?: any): void => {
    setModalContent(
      <ExtractExportModal
        confirm={(fileName) => handleExportExtract(fileName, singleResult)}
        cancel={closeModal}
      />
    );
  };

  const headCells: HeadCell<JobRow>[] = [
    {
      id: "id",
      label: "",
      // sortable: false,
      style: {
        width: "1%",
        maxWidth: 80,
      },
      formatter: (j: JobRow) => {
        return (
          <div className="firstCell lightGray">{`#${j.elasticJob.id_job}`}</div>
        );
      },
      barColActions: [
        {
          name: "export",
          icon: faArrowDownToLine,
          action: () => openExportJobModal(),
          disabled: selected?.length < 2,
        },
      ],
    },
    {
      id: "name",
      label: t("origin_file"),
      style: { width: 300, maxWidth: 300 },
      formatter: (j: JobRow) => {
        return (
          <div className="tableCell">
            <Link to={`/job/${j.elasticJob.id_job}`}>{j.name}</Link>
          </div>
        );
      },
    },
    {
      id: "tags",
      label: t("tags"),
      style: { width: 150, maxWidth: 150 },
      formatter: (r: JobRow) => (
        <div
          onClick={() => openTagsModal([r], r.tags)}
          className="tableCell pointer"
        >
          {getShortArrayString(r.tags.map((r) => r.name)) || (
            <div className={"tagsColumn"}>
              <FontAwesomeIcon
                size={"sm"}
                className={"addTagAddIcon"}
                icon={faAdd}
              />
              <p className={"addTagText"}>Add tag</p>
            </div>
          )}
        </div>
      ),
    },
    {
      id: "speakers",
      label: t("speakers"),
      style: { width: 150, maxWidth: 150 },
      formatter: (j: JobRow) => (
        <div className="tableCell">
          <p>{j.speakers?.join(", ")}</p>
        </div>
      ),
    },
    {
      id: "tc",
      label: t("tc"),
      style: { width: 80, maxWidth: 80 },
      formatter: (j: JobRow) => {
        return (
          <div className="tableCell">
            <Link to={`/job/${j.elasticJob.id_job}?tc=${Math.floor(j.tc)}`}>
              {secondsToTimeString(j.tc, true)}
            </Link>
          </div>
        );
      },
    },
    {
      id: "transcript",
      label: t("transcript"),
      formatter: (j) => transcriptTextFormatter(j),
    },
    {
      id: "contextMenu",
      sortable: false,
      label: "",
      style: { width: 60, maxWidth: 60 },
      formatter: renderRowContext,
    },
  ];

  const createJobRow = (job: any): JobRow => {
    return {
      id: job.id,
      name: job.name,
      lang: job.input_lang,
      tags: job.dbJob?.tags || [],
      speakers: job.speakers,
      speaker: job.speaker,
      tc: job.tc,
      transcript: job.text,
      contextMenu: null,
      elasticJob: job,
      dbJob: job.dbJob,
    };
  };

  const transcriptTextFormatter = (j: JobRow) => {
    const searchQueryWords = searchQuery.split(" ").filter((w) => w);
    const formattedIntervalText = [];

    for (const speakerTranscript of j.transcript) {
      const formattedSpeakerText = [];
      const transcriptTextWords = speakerTranscript.text.split(" ");

      for (let i = 0; i < transcriptTextWords.length; i++) {
        const word = transcriptTextWords[i];
        if (
          _.some(
            _.map(searchQueryWords, (sqWord) =>
              word.includes(
                sqWord.toLowerCase().replace(/[!%(){};:<>.,?-_]/g, "")
              )
            )
          )
        ) {
          formattedSpeakerText.push(
            <b style={{ margin: 2, display: "inline-block" }} key={i}>
              {word}
            </b>
          );
        } else {
          formattedSpeakerText.push(
            <span style={{ margin: 2, display: "inline-block" }} key={i}>
              {word}
            </span>
          );
        }
      }
      formattedIntervalText.push(
        <div>
          <u>{speakerTranscript.speaker}:</u>
          <span>{formattedSpeakerText}</span>
        </div>
      );
    }

    return (
      <div style={{ direction: getLanguageDirection(j.lang) }}>
        {formattedIntervalText}
      </div>
    );
  };

  const onSearch = async (e: any) => {
    setSearchQuery(e.target.value.toLocaleLowerCase());
  };

  return (
    <Grid container className="Extract Page">
      <Grid container justifyContent={"center"} pb={4}>
        <Grid item xs={11} className="titleWrapper">
          <p className="pageTitle">{t("extract")}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11} pb={2}>
          <SearchInput
            value={searchQuery}
            onChange={onSearch}
            showTextInput={true}
            placeholder={t("search")}
            style={{
              color: grayColor4,
              fontSize: "16px",
              justifyContent: "space-between",
            }}
            hasFilter={true}
            filterBtnText={t("filter")}
            isFilterOpen={isFilterOpen}
            toggleFilter={setIsFilterOpen}
            direction={t("dir")}
          >
            <ExtractFilters
              setFilters={setFilters}
              filters={{
                created_at: {
                  name: t("uploaded"),
                  type: "date",
                },
                job_type: {
                  name: t("type"),
                  type: "list",
                  options: jobTypes.map((t) => ({ value: t, label: t })),
                },
                // lang: {
                //   name: t("language"),
                //   type: "list",
                //   options: languages.map((l) => ({
                //     label: t(l.value),
                //     value: l.value,
                //   })),
                // },
                // duration: {
                //   name: t("duration"),
                //   type: "range",
                //   max: FILTER_MAX_DURATION,
                // },
                tags: {
                  name: t("tags"),
                  type: "list",
                  options: tags.map((t) => ({
                    value: t.name,
                    label: t.name,
                  })),
                },
                speaker: {
                  name: t("speakers"),
                  type: "list",
                  options: speakers.map((s) => ({
                    value: s.name,
                    label: s.name,
                  })),
                },
                // project: {
                //   name: t("project"),
                //   type: "list",
                //   options: [],
                // },
              }}
            />
          </SearchInput>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} pb={1}>
        <Grid item xs={11} className="titleWrapper">
          <p className="pageSubTitle">
            {searchResults.length
              ? t("showing_results", { count: searchResults.length })
              : t("no_results")}
          </p>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11}>
          <GeneralTable
            headCells={headCells}
            rows={resultsTableData}
            selected={selected}
            setSelected={setStelected}
            onLoadMore={loadOnScroll}
            loading={loadingJobs}
            hasMore={hasMore}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Extract;
