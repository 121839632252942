import React from "react";
import { ContentScrollProps } from "../../SlateEditor";
import { Languages } from "@sumit-platforms/types";
import SourceWordsRollup from "./SourceWordsRollup";

import "./EditorRollup.scss";

interface Props {
  height: number;
  contentScroll: ContentScrollProps;
  inputLanguage?: Languages | null;
}

const EditorRollup = ({ height, contentScroll, inputLanguage }: Props) => {
  if (!inputLanguage) return null;
  return (
    <SourceWordsRollup
      height={height}
      contentScroll={contentScroll}
      inputLanguage={inputLanguage}
    />
  );
};

export default EditorRollup;
