import _ from "lodash";
import {
  faMobileScreen,
  faShieldHalved,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { useTranslation } from "react-i18next";
import { bazarTheme } from "../../bazar-theme";
import { useState, useEffect } from "react";
import { MFAMethods } from "@sumit-platforms/types";

import "../../../style/auth.scss";

interface Props {
  enrollSMS: () => Promise<void>;
  validateMfaSession: () => Promise<any>;
  enrollTOTP: () => Promise<void>;
}

export const MFAPage = ({
  enrollSMS,
  enrollTOTP,
  validateMfaSession,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState("");

  useEffect(() => {
    // validateMfaSession();
  }, []);

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="MFAPage AuthPage MFA">
        <div className="authContainer">
          <div className="headerTitle">
            <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
          </div>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h5"
              component="h1"
              align="center"
              color="text.primary"
            >
              {t("mfa_setup_title")}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              color="text.secondary"
            >
              {t("mfa_choose")}
            </Typography>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              startIcon={
                loading === MFAMethods.sms ? (
                  <CircularProgress size={20} />
                ) : (
                  <FontAwesomeIcon icon={faMobileScreen} />
                )
              }
              onClick={async () => {
                setLoading(MFAMethods.sms);
                await enrollSMS();
                setLoading("");
              }}
              disabled={!_.isEmpty(loading)}
              sx={{ justifyContent: "flex-start", py: 2 }}
            >
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography variant="subtitle1">SMS Authentication</Typography>
                <Typography variant="body2" color="text.secondary">
                  Receive verification codes via text message
                </Typography>
              </Box>
            </Button>

            <Button
              variant="outlined"
              size="large"
              startIcon={
                loading === MFAMethods.totp ? (
                  <CircularProgress size={20} />
                ) : (
                  <FontAwesomeIcon icon={faShieldHalved} />
                )
              }
              onClick={async () => {
                setLoading(MFAMethods.totp);
                await enrollTOTP();
                setLoading("");
              }}
              disabled={!_.isEmpty(loading)}
              sx={{ justifyContent: "flex-start", py: 2 }}
            >
              <Box sx={{ textAlign: "left", ml: 2 }}>
                <Typography variant="subtitle1">Authenticator App</Typography>
                <Typography variant="body2" color="text.secondary">
                  Use an authenticator app like Google Authenticator or Authy
                </Typography>
              </Box>
            </Button>
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};
