import { currentTimeState } from "../../store/states";
import { Tooltip } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useMemo, useEffect, useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationCrosshairsSlash,
  faLocationCrosshairs,
} from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { Direction } from "@sumit-platforms/types";
import Box from "@mui/material/Box";
import { mainYellow, secondaryBlue } from "@sumit-platforms/ui-bazar/constants";

import "./SyncScroll.scss";

export interface ItemRange {
  text: string;
  subtext?: string;
  st: number;
  et: number;
  index: number;
}

export const SyncScroll = ({
  syncScroll,
  setSyncScroll,
  listRef,
  ranges,
  itemHeight,
  dir,
  height,
}: {
  syncScroll: boolean;
  setSyncScroll: React.Dispatch<React.SetStateAction<boolean>>;
  listRef?: HTMLDivElement | null;
  ranges: ItemRange[];
  itemHeight: number;
  dir: Direction;
  height: number;
}) => {
  const { t } = useTranslation();
  const currentTime = useRecoilValue(currentTimeState);

  const rangesStartTimes = useMemo(() => {
    return ranges.map((range) => {
      return range.st;
    });
  }, [ranges]);

  const getTheClosesStartTime = useCallback(() => {
    if (!currentTime) return;
    const onlyBefore = rangesStartTimes.filter(
      (st: number) => st <= currentTime
    );
    if (onlyBefore.length === rangesStartTimes.length) {
      return rangesStartTimes.length - 2;
    }

    return onlyBefore.length - 1;
  }, [currentTime, rangesStartTimes]);

  const relocate = useCallback(() => {
    if (!listRef) return;
    const st = getTheClosesStartTime();
    if (!_.isNumber(st)) return;

    listRef.scrollTo({ top: st * itemHeight, behavior: "smooth" });
  }, [getTheClosesStartTime, itemHeight, listRef]);

  const handleSyncScrollOnBetweenRanges = useCallback(() => {
    if (!_.isNumber(listRef?.scrollTop) || !_.isNumber(currentTime)) return;
    const firstItemIndex = Math.floor(listRef.scrollTop / itemHeight);
    const visibleRanges = ranges.slice(firstItemIndex, firstItemIndex + 9);

    const isTimeInRange =
      currentTime >= visibleRanges[0]?.st &&
      currentTime <= visibleRanges[visibleRanges.length - 1]?.et;

    if (!isTimeInRange) {
      relocate();
    }
  }, [currentTime, itemHeight, listRef, ranges, relocate]);

  const scrollIntoVisibleRange = useCallback(
    (visibleRange: ItemRange) => {
      const visibleRangeIndex = visibleRange.index;
      const itemElement = document.getElementById(
        `range-item-${visibleRangeIndex}`
      );
      if (!itemElement) handleSyncScrollOnBetweenRanges();

      itemElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    [handleSyncScrollOnBetweenRanges]
  );

  useEffect(() => {
    if (
      !syncScroll ||
      !ranges ||
      ranges.length === 0 ||
      !_.isNumber(currentTime)
    )
      return;

    // Find the range that includes the current time
    const visibleRange = ranges.find(
      (range) => currentTime >= range.st && currentTime <= range.et
    );

    if (visibleRange) {
      scrollIntoVisibleRange(visibleRange);
    } else {
      handleSyncScrollOnBetweenRanges();
    }
  }, [
    currentTime,
    syncScroll,
    ranges,
    handleSyncScrollOnBetweenRanges,
    scrollIntoVisibleRange,
  ]);

  const handleSyncScroll = useCallback(() => {
    setSyncScroll(!syncScroll);
  }, [setSyncScroll, syncScroll]);

  return (
    <Box dir={dir} className={"SyncScroll"} px={0} py={0} height={height}>
      <Tooltip
        title={t(syncScroll ? "disable_sync_scroll" : "enable_sync_scroll")}
      >
        <IconButton onClick={handleSyncScroll} className={"SyncButton"}>
          <FontAwesomeIcon
            icon={syncScroll ? faLocationCrosshairs : faLocationCrosshairsSlash}
            color={syncScroll ? mainYellow : secondaryBlue}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
