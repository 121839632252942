import React, {
  forwardRef,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlateStatic,
} from "@sumit-platforms/slate-react";
import EditorService from "./services/EditorService";
import {
  CustomEditor,
  EditorAction,
  MarkWordMetadata,
  SubtitleRangeElement,
} from "./types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  featureFlagsState,
  isDisabledState,
  isJobScriptOpenState,
  isValidationsOpenState,
  repeatState,
  editorHeightState,
  focusRangeNavigatorInputState,
  currentTimeState,
  isFindAndReplaceOpenState,
  startIndexState,
  isBetweenRangesState,
} from "./store/states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { EditorForwardedRef, ContentScrollProps } from "./SlateEditor";
import { ActionElement } from "./components/ActionElement/ActionElement";
import _ from "lodash";
import MediaService from "./services/MediaService";
import { FindAndReplace } from "./components/FindAndReplace/FindAndReplace";
import { JobScript } from "./components/JobScript/JobScript";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { JobValidationsExplorer } from "./components/JobValidations/JobValidationsExplorer";
import useResize from "./hooks/useResize";
import EditorRollup from "./components/EditorRollup/EditorRollup";
import { Languages } from "@sumit-platforms/types";

import "./SubtitlesEditor.scss";

interface TranscriptEditorProps {
  className?: string;
  renderElement: (props: RenderElementProps) => JSX.Element;
  renderLeaf: (props: RenderLeafProps) => JSX.Element;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleBlur: (rangeIndex?: number) => void;
  addActions: (actions: EditorAction[]) => void;
  isTranslationMode: boolean;
  inputLanguage?: Languages | null;
}

const SubtitlesEditor = (
  {
    renderElement,
    renderLeaf,
    onKeyDown,
    className,
    handleBlur,
    addActions,
    isTranslationMode,
    inputLanguage,
  }: TranscriptEditorProps,
  ref: React.Ref<EditorForwardedRef>
) => {
  const editorController = useSlateStatic() as CustomEditor;
  const featureFlags = useRecoilValue(featureFlagsState);
  const [isJobScriptOpen, setIsJobScriptOpen] =
    useRecoilState(isJobScriptOpenState);
  const rangeNavInput = useRecoilValue(focusRangeNavigatorInputState);
  const disabled = useRecoilValue(isDisabledState);
  const setRepeat = useSetRecoilState(repeatState);
  const setEditorHeight = useSetRecoilState(editorHeightState);
  const setStartIndex = useSetRecoilState(startIndexState);
  const setTime = useSetRecoilState(currentTimeState);
  const [isValidationOpen, setIsValidationOpen] = useRecoilState(
    isValidationsOpenState
  );
  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useRecoilState(
    isFindAndReplaceOpenState
  );

  const { windowHeight } = useResize(); // trigger re-render on window resize to re-build editor layout

  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const isMediaWasPlayingOnScrollStart = useRef(false);
  const editorRef = useRef<HTMLElement | null>(null);

  const resetFindAndReplaceMarks = useCallback(() => {
    EditorService.removeMarks({
      editor: editorController,
      markKey: "highlightGreen",
    });
  }, [editorController]);

  const afterFindAndReplaceDecoration = useCallback(
    (search: string, marks: MarkWordMetadata[] | null) => {
      resetFindAndReplaceMarks();
      EditorService.addMarks({
        editor: editorController,
        marks,
        markKey: "highlightGreen",
        search,
      });
    },
    [editorController, resetFindAndReplaceMarks]
  );

  const onReplaceOne = useCallback(
    (
      markedWord: MarkWordMetadata | null,
      newText: string,
      marks: MarkWordMetadata[]
    ) => {
      EditorService.replaceOne({
        editor: editorController,
        markedWord,
        newText,
        shouldUpdateRangeWords: false,
        mode: "subtitles",
        marks,
      });
    },

    [editorController]
  );

  useImperativeHandle(
    ref,
    () => ({
      editorRef: editorRef.current,
    }),
    [editorRef]
  );

  const addValidationsEditorAction = () => {
    addActions([
      {
        key: "validations",
        label: "validations",
        icon: faCircleExclamation,
        onClick: handleValidationClick,
        selected: isValidationOpen,
      },
    ]);
  };
  const addEditorActions = useCallback(() => {
    addValidationsEditorAction();
  }, [addValidationsEditorAction]);

  useLayoutEffect(() => {
    addEditorActions();
  }, [isJobScriptOpen, isValidationOpen]);

  const updateEditorMode = useCallback(() => {
    if (editorController.mode) return;
    editorController.mode = isTranslationMode
      ? "subtitles-translation"
      : "subtitles";
  }, [editorController, isTranslationMode]);

  useEffect(() => {
    if (!editorController) return;
    updateEditorMode();
    try {
      const _editorRef = ReactEditor.toDOMNode(
        editorController,
        editorController
      );
      editorRef.current = _editorRef;
    } catch (e) {
      console.error("e :", e);
    }
  }, [editorController]);

  const handleValidationClick = useCallback(() => {
    const updatedValidationOpen = !isValidationOpen;
    setIsValidationOpen(updatedValidationOpen);
    if (updatedValidationOpen) {
      if (isJobScriptOpen) {
        setIsJobScriptOpen(false);
      }
      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      }
    }
  }, [
    isValidationOpen,
    setIsValidationOpen,
    isJobScriptOpen,
    setIsJobScriptOpen,
    isFindAndReplaceOpen,
    setIsFindAndReplaceOpen,
  ]);

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      EditorService.focusToNextOrPrevSubtitle(editorController, "next");
    },

    [editorController]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      EditorService.focusToNextOrPrevSubtitle(editorController, "prev");
    },
    [editorController]
  );

  const onBlur = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const lastCursoredElement =
        EditorService.getLastCursoredElement(editorController);
      if (!lastCursoredElement?.element) return;
      const isChanged = EditorService.isRangeChanged({
        element: lastCursoredElement.element as SubtitleRangeElement,
      });
      if (!isChanged) return;
      handleBlur(lastCursoredElement.path);
    },
    [editorController, handleBlur]
  );

  const handleGlitchTextToNextRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const entry = EditorService.getLastCursoredElement(editorController);
      if (!_.isNumber(entry?.path)) return;
      EditorService.glitchTextToNextRange(editorController, entry);
    },
    [editorController, handleBlur]
  );

  const handleAddFrames = useCallback(
    _.throttle((framesToAdd: number, position: "start" | "end") => {
      const isFocused = ReactEditor.isFocused(editorController);
      const rangeIndex = isFocused
        ? editorController.selection?.anchor?.path[0] ||
          editorController.playingRangeIndex
        : editorController.playingRangeIndex;

      if (editorController?.isBetweenRanges || !_.isNumber(rangeIndex)) {
        return;
      }
      const element = editorController.children[
        rangeIndex
      ] as SubtitleRangeElement;

      const minLimit = position === "end" ? element.range.st + 0.01 : undefined;
      const maxLimit =
        position === "start" ? element.range.et - 0.01 : undefined;

      EditorService.handleAddFrames({
        editor: editorController,
        framesToAdd,
        position,
        rangeIndex,
        element: element,
        disabled,
        limit: {
          min: minLimit,
          max: maxLimit,
        },
      });
    }, 100),
    [editorController, disabled]
  );

  const handleAddFramesKeystroke = useCallback(
    (e: KeyboardEvent, framesToAdd: number, position: "start" | "end") => {
      e.preventDefault();
      e.stopPropagation();
      handleAddFrames(framesToAdd, position);
    },
    [handleAddFrames]
  );

  const handleFocusRangeNavigatorKeystroke = useCallback(
    (e: KeyboardEvent) => {
      if (rangeNavInput) {
        e.preventDefault();
        e.stopPropagation();
        rangeNavInput?.select();
      }
    },
    [rangeNavInput]
  );

  const handleDeleteRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      EditorService.deleteEntireRange(editorController);
    },
    [editorController]
  );
  const handleNewBreakRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      editorController.splitNodes({ always: true });
    },
    [editorController]
  );

  const handleSkipToNextRange = useCallback(
    _.throttle(() => {
      const result = EditorService.onSkipSubtitle({ editor: editorController });
      return result;
    }, 100),
    [editorController]
  );

  const handleSkipToPrevRangeKeystroke = useCallback(
    _.throttle(() => {
      const result = EditorService.onPrevSubtitle({ editor: editorController });
      return result;
    }, 100),
    [editorController]
  );

  const handleSkipToNextRangeKeystroke = useCallback(
    (e: KeyboardEvent, to: "prev" | "next") => {
      e.preventDefault();
      e.stopPropagation();
      let result;
      if (to === "next") {
        result = handleSkipToNextRange();
      }
      if (to === "prev") {
        result = handleSkipToPrevRangeKeystroke();
      }

      if (result?.st && result?.newIndex && editorController) {
        setTime(result.st);
        EditorService.focusByPathOrElement(editorController, {
          path: [result.newIndex],
        });
      }
    },
    [
      editorController,
      handleSkipToNextRange,
      handleSkipToPrevRangeKeystroke,
      setTime,
    ]
  );

  const handleRepeatKeystroke = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const repeat = MediaService.setIsRepeat(!MediaService.isRepeat);
      setRepeat(repeat);
    },
    [setRepeat]
  );

  const visibleRanges = useMemo(() => 3, []);

  const { subtitleHeight, mediaHeight } = EditorService.calculateRangeHeight({
    visibleRanges,
    elementInnerHeight: editorWrapperRef.current?.clientHeight,
  });

  const editorHeight = useMemo(() => {
    const height = subtitleHeight * (visibleRanges - 1) + mediaHeight;
    setEditorHeight(height);
    return height;
  }, [mediaHeight, setEditorHeight, subtitleHeight, visibleRanges]);

  const mediaIndex = useMemo(
    () => Math.floor(visibleRanges / 2),
    [visibleRanges]
  );

  const setMediaContentScrollOffset = useCallback(
    (index: number) => {
      const st = (
        editorController.children[index + mediaIndex] as SubtitleRangeElement
      )?.range?.st;
      MediaService.setOffset(st);
    },
    [mediaIndex, editorController.children]
  );

  const handleContentScrollIndexChange = useCallback(
    (index: number) => {
      setStartIndex(index);
    },
    [setStartIndex]
  );

  const handleContentScrollStop = useCallback(
    (index: number) => {
      setMediaContentScrollOffset(index);
      if (isMediaWasPlayingOnScrollStart.current) MediaService.play();
    },
    [setMediaContentScrollOffset, isMediaWasPlayingOnScrollStart]
  );

  const handleContentScrollStart = useCallback(
    (index: number) => {
      if (MediaService.repeat) {
        const repeat = MediaService.setIsRepeat(!MediaService.isRepeat);
        setRepeat(repeat);
      }
      isMediaWasPlayingOnScrollStart.current = MediaService.isPlaying;
    },
    [isMediaWasPlayingOnScrollStart, setRepeat]
  );

  const actionSectionElement = useMemo(() => {
    if (isFindAndReplaceOpen) {
      return (
        <FindAndReplace
          onReplaceOne={onReplaceOne}
          close={() => setIsFindAndReplaceOpen(false)}
          afterTriggerDecorations={afterFindAndReplaceDecoration}
          onResetState={resetFindAndReplaceMarks}
          mode={"subtitles"}
          minimumFindInputLengthToTrigger={2}
        />
      );
    }
    if (isJobScriptOpen) {
      return <JobScript isTranslationMode={isTranslationMode} />;
    }

    if (isValidationOpen) {
      return <JobValidationsExplorer />;
    }

    return null;
  }, [
    isTranslationMode,
    isFindAndReplaceOpen,
    isJobScriptOpen,
    onReplaceOne,
    setIsFindAndReplaceOpen,
    afterFindAndReplaceDecoration,
    resetFindAndReplaceMarks,
    isValidationOpen,
  ]);

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
      GLITCH_TEXT_TO_NEXT_RANGE: handleGlitchTextToNextRange,
      DELETE_ENTIRE_RANGE: handleDeleteRange,
      BREAK_RANGE: handleNewBreakRange,
    },
    ref: editorRef.current,
  });

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      SKIP_TO_NEXT_SUBTITLE: (e: any) =>
        handleSkipToNextRangeKeystroke(e, "next"),
      SKIP_TO_PREV_SUBTITLE: (e: any) =>
        handleSkipToNextRangeKeystroke(e, "prev"),
      REPEAT: handleRepeatKeystroke,
      ADD_START_TIME_FRAME: (e: any) => handleAddFramesKeystroke(e, 1, "start"),
      SUB_START_TIME_FRAME: (e: any) =>
        handleAddFramesKeystroke(e, -1, "start"),
      ADD_END_TIME_FRAME: (e: any) => handleAddFramesKeystroke(e, 1, "end"),
      SUB_END_TIME_RANGE: (e: any) => handleAddFramesKeystroke(e, -1, "end"),
      FOCUS_RANGE_NAVIGATOR: (e: any) => handleFocusRangeNavigatorKeystroke(e),
    },
  });

  const contentScroll = {
    onIndexChange: handleContentScrollIndexChange,
    onScrollStop: handleContentScrollStop,
    onScrollStart: handleContentScrollStart,
    subtitleHeight,
    mediaHeight,
    visibleRanges,
  } as ContentScrollProps;
  return (
    <div
      className={"subtitlesEditorContent"}
      onKeyDown={onKeyDown}
      ref={editorWrapperRef}
    >
      <Editable
        className={className}
        readOnly={disabled}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        contentScroll={contentScroll}
        onBlur={onBlur}
        style={{
          height: editorHeight,
        }}
      />
      <ActionElement hide={!actionSectionElement}>
        {actionSectionElement}
      </ActionElement>
      {isTranslationMode && !actionSectionElement && (
        <EditorRollup
          height={editorHeight}
          contentScroll={contentScroll}
          inputLanguage={inputLanguage}
        />
      )}
    </div>
  );
};

export default forwardRef(SubtitlesEditor);
