import { useState, useEffect } from "react";

const useResize = () => {
  const [windowHeight, setWindowHeight] = useState(
    window ? window.innerHeight : 0
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return { windowHeight };
};

export default useResize;
