import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { useSetRecoilState } from "recoil";
import { CustomEditor } from "../../types";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import EditorService from "../../services/EditorService";
import {
  currentTimeState,
  focusRangeNavigatorInputState,
} from "../../store/states";

const debounceMs = 350;

const RangeNavigator = ({ editor }: { editor?: CustomEditor }) => {
  const { t } = useTranslation();
  const setSubtitleNavInput = useSetRecoilState(focusRangeNavigatorInputState);
  const setTime = useSetRecoilState(currentTimeState);
  const [rangeNumber, setRangeNumber] = useState(
    (editor?.playingRangeIndex || 0) - 1
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const totalPlaceholderCount = useMemo(
    () =>
      editor
        ? EditorService.getPlaceholderRangesCount(
            editor,
            editor.children?.length
          )
        : 0,
    [editor]
  );

  const totalRanges = useMemo(
    () =>
      editor?.children?.length
        ? editor?.children?.length - totalPlaceholderCount
        : null,
    [editor?.children?.length, totalPlaceholderCount]
  );

  useEffect(() => {
    if (!_.isNumber(editor?.playingRangeIndex)) return;
    if (rangeNumber !== editor?.playingRangeIndex) {
      setRangeNumber(editor?.playingRangeIndex - 1);
    }
  }, [editor?.playingRangeIndex]);

  const debounceNavigation = useCallback(
    _.debounce((index: number) => {
      if (!editor) return;
      const placeholderCount = index >= 1 ? 1 : 0;
      const navigateToIndex = index + placeholderCount;
      const st = EditorService.getSubtitleRangeStartTime(
        editor,
        navigateToIndex
      );
      setTime(st);
      EditorService.navigateToSubtitle({ editor, st });
      EditorService.focusByPathOrElement(editor, { path: [navigateToIndex] });
    }, debounceMs),
    [debounceMs, editor]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.value || !totalRanges) return;
      let newValue = Number(e.target?.value);
      if (newValue > totalRanges) newValue = totalRanges;
      setRangeNumber(Number(newValue));
      debounceNavigation(newValue);
    },
    [totalRanges, debounceNavigation]
  );

  const handleFocusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, [inputRef]);

  useEffect(() => {
    if (inputRef.current) {
      setSubtitleNavInput(inputRef.current);
    }
  }, [inputRef, setSubtitleNavInput]);

  return (
    <Box className={"SubtitleNavigator"} onClick={handleFocusInput}>
      <p>{t("subtitle")}</p>
      <input
        min={0}
        max={totalRanges || 999}
        ref={inputRef}
        type={"number"}
        className={"subtitleNavigatorInput"}
        value={rangeNumber}
        onChange={handleInputChange}
      />
      <p>
        {t("from")} {totalRanges}
      </p>
    </Box>
  );
};

export default RangeNavigator;
