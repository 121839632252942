import React from "react";

import "./DiffRange.scss";

interface DiffRangeProps {
  height: number;
  className?: string;
  children?: React.ReactNode;
}

const DiffRange = ({ height, className, children }: DiffRangeProps) => {
  return (
    <div
      className={"DiffRange " + (className || "")}
      style={{
        height: `${height}px`,
      }}
    >
      <div className={"diffRangeContent"}>{children}</div>
    </div>
  );
};

export default DiffRange;
