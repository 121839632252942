import React, { useCallback } from "react";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { useRecoilValue } from "recoil";
import { currentTimeState } from "../../store/states";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-thin-svg-icons";
import { useTranslation } from "react-i18next";
import EditorService from "../../services/EditorService";
import { CustomEditor, SubtitleRangeElement } from "../../types";
import _ from "lodash";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import MediaService from "../../services/MediaService";

import "./CurrentTimeSetter.scss";

export const CurrentTimeSetter = ({
  position,
  disabled,
}: {
  position?: "st" | "et";
  disabled?: boolean;
}) => {
  const editor = useSlateStatic() as CustomEditor;
  const { t } = useTranslation();
  const currentTime = useRecoilValue(currentTimeState);

  const setCurrentTime = useCallback(() => {
    if (!_.isNumber(editor.startIndex) || disabled) return;
    const index = editor.startIndex + 1;
    const element = editor.children[index] as SubtitleRangeElement;
    if (
      !element ||
      element.type !== "subtitleRange" ||
      !position ||
      !_.isNumber(currentTime)
    )
      return;
    const newRange = EditorService.updateRangeOnFrameChange(
      element,
      position === "st" ? "start" : "end",
      currentTime
    );
    EditorService.updateNodeData({
      data: {
        range: newRange,
      },
      element,
      editor,
    });

    EditorService.updateElementRange({
      editor,
      entry: {
        element: editor.children[index] as SubtitleRangeElement,
        path: index,
      },
    });
    EditorService.reorderRangeWords(editor, index);
    EditorService.createWaveformRanges(editor, false);
  }, [currentTime, disabled, editor, position]);

  return currentTime ? (
    <div
      className={classNames("CurrentTimeSetter", { disabled })}
      onClick={setCurrentTime}
    >
      <FontAwesomeIcon icon={faClock} />
      <span>
        {t("set")}{" "}
        {getTimecodeFromSeconds(currentTime, {
          fps: MediaService.frameRate,
        })}
      </span>
    </div>
  ) : null;
};
