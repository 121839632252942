import { useSlate } from "./use-slate";
import React, {
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { Node } from "@sumit-platforms/slate";
import _ from "lodash";
import { CustomChildrenProps } from "../components/editable";

const rangeStyle: React.CSSProperties = {
  boxSizing: "border-box",
  padding: "0px",
  margin: "0px",
  position: "sticky",
};

//Start index = the index of the first item out of the entire list
//Render index = the index of the first VISIBLE item out of the rendered list (between 0 - visibleRanges)

const ScrollableSubtitlesEditor = ({
  children,
  contentScroll,
}: {
  children: any;
  contentScroll: CustomChildrenProps["contentScroll"];
}) => {
  const editor = useSlate();
  const containerRef = useRef<HTMLDivElement | null>(null); // TODO: add type
  const [startIndex, setStartIndex] = useState(0);
  const [time, setTime] = useState(0);
  const startIndexRef = useRef(0);
  const skipHandleScroll = useRef(false);
  const onScrollStopTimeout = useRef<NodeJS.Timeout | null>(null);
  const scrollEndTriggerMs = useMemo(() => 100, []);

  const { subtitleHeight, mediaHeight, visibleRanges } = contentScroll || {};

  if (visibleRanges !== 3) {
    throw new Error("Currently, Sumit is only support 3 visible ranges!");
  }

  const rangesStartTimes = useMemo(() => {
    return children
      .map((child: any) => {
        const range = child.props.children.props.element?.range;
        return range ? { st: range.st, et: range.et } : null;
      })
      .filter(Boolean);
  }, [children]);

  const middleRangeIndex = useMemo(
    () => Math.floor(visibleRanges / 2),
    [visibleRanges]
  );
  const isScrolling = useRef<any>(null);

  const mediaEt = (
    editor.children[middleRangeIndex + startIndexRef.current] as any
  ).range.et;

  const itemsHeight = useMemo(() => {
    const rangesHeights = Array.from(
      { length: visibleRanges },
      () => subtitleHeight || 0
    );
    rangesHeights[middleRangeIndex] = mediaHeight || 0;
    return rangesHeights;
  }, [mediaHeight, subtitleHeight, visibleRanges]);

  // const thirdRangeSt = (
  //   editor.children[
  //     middleRangeIndex + startIndexRef.current + 1
  //   ] as SpeakerRangeElement
  // ).range.st;

  const isBetweenRanges = time > mediaEt && !isScrolling.current; // && time < thirdRangeSt;

  const cursorPosition = useRef<{
    startScrollingRenderIndex: number | null;
    startScrollingCursorOffset: number | null;
  } | null>(null);

  const middleRangeGap =
    (itemsHeight[middleRangeIndex] - itemsHeight[0]) / itemsHeight[0];

  const hiddenContainerHeight =
    itemsHeight[0] * (children.length + middleRangeGap);

  const visibleContainerHeight = itemsHeight.reduce(
    (partialSum: any, a: any) => partialSum + a,
    0
  );

  const getTop = useCallback(
    (index: number) => {
      let top = 0;
      for (let i = 0; i < index; i++) {
        top += itemsHeight[i];
      }
      return top;
    },
    [itemsHeight]
  );

  const getShouldScroll = useCallback(
    (scrollTop: number) => {
      if (!containerRef.current) return false;
      const shouldScroll = scrollTop !== containerRef.current?.scrollTop;
      return shouldScroll;
    },
    [containerRef]
  );

  const getTheClosesStartIndex = useCallback(
    (time: number): any => {
      const onlyBefore = rangesStartTimes.filter(
        (times: { st: number; et: number }) => times.st <= time
      );
      if (onlyBefore.length === rangesStartTimes.length) {
        return rangesStartTimes.length - 2;
      }

      return onlyBefore.length - 1;
    },
    [rangesStartTimes]
  );

  const handleTimeChange = (
    time: number,
    preventCursorManipulation = false
  ) => {
    if (!containerRef.current || isScrolling.current) return;
    setTime(time);
    const currentPlayingRangeIndex = getTheClosesStartIndex(time);

    if (currentPlayingRangeIndex >= 0) {
      const newIndex = currentPlayingRangeIndex - 1;
      if (newIndex === -1) return; // Handle the first range case

      if (newIndex !== startIndexRef.current) {
        if (!preventCursorManipulation) {
          saveCursorPosition();
          clearCursor();
        }
        skipHandleScroll.current = true;
        const scrollTop = currentPlayingRangeIndex * itemsHeight[0];
        const shouldScroll = getShouldScroll(scrollTop);
        if (shouldScroll) {
          containerRef.current.scrollTop = scrollTop;
        }
        handleChangeStartIndex(newIndex);
        setTimeout(() => {
          if (preventCursorManipulation) return;
          restoreCursorPosition(startIndexRef.current);
          skipHandleScroll.current = false;
        }, 50);
      }
      return;
    }
  };

  const handleChangeStartIndex = (newStartIndex: number) => {
    setStartIndex(newStartIndex);
    startIndexRef.current = newStartIndex;
    contentScroll?.onIndexChange(newStartIndex);
  };

  const debouncedScrollStop = () => {
    if (onScrollStopTimeout.current) {
      clearTimeout(onScrollStopTimeout.current);
    }

    onScrollStopTimeout.current = setTimeout(onScrollStop, scrollEndTriggerMs);
  };

  const handleScroll = () => {
    if (skipHandleScroll.current) return;
    if (!containerRef.current) return;

    if (!isScrolling.current) {
      if (contentScroll?.onScrollStart) {
        contentScroll?.onScrollStart(startIndexRef.current + 1);
      }
      saveCursorPosition();
      isScrolling.current = true;
      editor.isContentScrolling = true;
    }

    clearCursor();
    const scrollTop = containerRef.current.scrollTop;
    const newStartIndex = Math.floor(scrollTop / (subtitleHeight || 0));

    if (newStartIndex !== startIndex) {
      handleChangeStartIndex(newStartIndex);
    }

    debouncedScrollStop();
  };

  const onScrollStop = () => {
    if (skipHandleScroll.current) return;

    if (contentScroll?.onScrollStop) {
      contentScroll?.onScrollStop(startIndexRef.current);
    }

    isScrolling.current = false;

    setTimeout(() => {
      editor.isContentScrolling = false;
    }, 250);

    if (typeof cursorPosition.current?.startScrollingRenderIndex !== "number") {
      return;
    }

    setTimeout(() => {
      restoreCursorPosition(startIndexRef.current);
    }, 50);
  };

  useEffect(() => {
    return () => {
      // Cleanup timeout when component unmounts
      if (onScrollStopTimeout.current) {
        clearTimeout(onScrollStopTimeout.current);
      }
    };
  }, []);

  const onWindowResize = () => {
    skipHandleScroll.current = true;

    setTimeout(() => {
      skipHandleScroll.current = false;
    }, 1);
  };

  const registerListeners = () => {
    if (containerRef.current) {
    }
    window.addEventListener("resize", onWindowResize);
  };

  const detachListeners = () => {
    if (containerRef.current) {
    }
    window.removeEventListener("resize", onWindowResize);
  };

  const restoreCursorPosition = (newIndex: number) => {
    if (
      typeof cursorPosition.current?.startScrollingRenderIndex !== "number" ||
      typeof cursorPosition.current?.startScrollingCursorOffset !== "number"
    ) {
      return;
    }

    const newCursorIndex =
      cursorPosition.current.startScrollingRenderIndex + newIndex;
    if (
      (editor.children[newCursorIndex] as any)?.type === "subtitlePlaceholder"
    ) {
      return;
    }
    const location = {
      path: [newCursorIndex, 0],
      offset: Math.min(
        cursorPosition.current.startScrollingCursorOffset,
        Node.string(editor.children[newCursorIndex])?.length
      ),
    };

    if (
      (editor.children[newCursorIndex] as any)?.type !== "subtitlePlaceholder"
    ) {
      if (
        !editor.selection ||
        editor.selection?.anchor.path[0] !== newCursorIndex
      ) {
        editor.select(location);
      }
    }
    cursorPosition.current = null;
  };

  const saveCursorPosition = () => {
    const windowSelection = window.getSelection();
    if (!editor.selection || !windowSelection?.anchorNode) {
      return;
    }
    if (cursorPosition.current?.startScrollingRenderIndex) return;
    const startScrollingRenderIndex =
      typeof (editor.selection?.anchor.path[0] - startIndexRef.current) ===
      "number"
        ? editor.selection?.anchor.path[0] - startIndexRef.current
        : null;

    cursorPosition.current = {
      startScrollingRenderIndex: startScrollingRenderIndex,
      startScrollingCursorOffset: editor.selection?.anchor.offset || 0,
    };
  };

  const clearCursor = () => {
    editor.deselect();
    editor.selection = null;
  };

  useEffect(() => {
    if (editor.startIndex !== startIndex) {
      editor.startIndex = startIndex;
    }
    if (editor.visibleRanges !== visibleRanges) {
      editor.visibleRanges = visibleRanges;
    }
    editor.handleTimeChange = handleTimeChange;
    if (editor.isBetweenRanges !== isBetweenRanges) {
      editor.isBetweenRanges = isBetweenRanges;
    }
    if (editor.playingRangeIndex !== startIndex + middleRangeIndex) {
      editor.playingRangeIndex = startIndex + middleRangeIndex;
    }
  }, [startIndex, handleTimeChange, isBetweenRanges, middleRangeIndex]);

  useEffect(() => {
    registerListeners();

    return detachListeners;
  }, [containerRef.current]);

  const getItem = (index: number, isMiddleRange = false) => {
    let _renderIndex = index;
    if (isBetweenRanges && index === 0) {
      // When in between two ranges, don't render the first item, because the time is passed
      // We are "skipping" the index 0 and hiding the 2nd item (using isPlaceholder)
      _renderIndex = 1;
    }
    const item = children[_renderIndex];
    // Inject render & start index props

    const child = cloneElement(item.props.children, {
      renderIndex: _renderIndex,
      startIndex,
      contentScroll,
      isMiddleRange,
      isPlaceholder: isMiddleRange && isBetweenRanges,
      isBetweenRanges: isBetweenRanges,
      selected:
        editor.selection?.focus?.path[0] ===
        _renderIndex + startIndexRef.current,
    });
    const element = cloneElement(item, {
      ...item.props,
      children: child,
    });
    return element;
  };

  return (
    <div className="ScrollableSubtitlesEditor staticScroller">
      <div
        ref={containerRef}
        style={{
          overflowY: "auto",
          width: "100%",
          height: `${visibleContainerHeight}px`,
          overflow: "auto",
          position: "relative",
        }}
        onScroll={handleScroll}
      >
        <div
          className="scroller"
          style={{
            width: "100%",
            height: `${hiddenContainerHeight}px`,
            position: "absolute",
          }}
        >
          <div
            style={{
              ...rangeStyle,
              height: itemsHeight[0],
              top: `${getTop(0)}px`,
            }}
          >
            {getItem(0)}
          </div>
          <div
            style={{
              ...rangeStyle,
              height: itemsHeight[1],
              top: `${getTop(1)}px`,
            }}
          >
            {getItem(1, true)}
          </div>
          <div
            style={{
              ...rangeStyle,
              height: itemsHeight[2],
              top: `${getTop(2)}px`,
            }}
          >
            {getItem(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollableSubtitlesEditor;
