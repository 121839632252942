import React, { useState } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons";
import { bazarTheme } from "../../bazar-theme";
import { ContextMenu, ContextOption, SpinningLoader } from "../../../index";

export interface TableContextProps {
  context: ContextOption[];
  row: any;
  disabled?: boolean;
}

export const TableContext = ({ context, row, disabled }: TableContextProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box
        className="BazarTableContext"
        px={1}
        id="basic-button"
        onClick={(e: any) => {
          if (disabled) return;
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
          handleClick(e);
        }}
      >
        {row.isLoading ? (
          <Box width={20} margin={"auto"}>
            <SpinningLoader />
          </Box>
        ) : (
          <>
            <FontAwesomeIcon
              size={"2x"}
              width={20}
              color={"#8593AA"}
              icon={faEllipsisVertical}
            />
            <ContextMenu
              onClose={handleClose}
              anchorEl={anchorEl}
              open={isOpen}
              context={context}
              setIsOpen={setIsOpen}
            />
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};
