import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/system";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import { CustomEditor, CustomElement, MarkWordMetadata } from "../../types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import EditorService from "../../services/EditorService";
import FindAndReplaceHighlightsList from "./FindAndReplaceHighlightsList";
import FindAndReplaceButtonsContainer from "./FindAndReplaceButtons";
import FindAndReplaceInputs from "./FindAndReplaceInputs";
import { Button } from "@sumit-platforms/ui-bazar";
import { freeze } from "@sumit-platforms/ui-bazar/utils";
import { useRecoilValue } from "recoil";
import { isDisabledState } from "../../store/states";
import { EditorMode } from "@sumit-platforms/types";

import "./FindAndReplace.scss";

interface FindAndReplaceProps {
  triggerDecoration?: React.Dispatch<React.SetStateAction<string>>;
  afterTriggerDecorations?: (
    term: string,
    marks: MarkWordMetadata[] | null
  ) => void;
  onReplaceOne: (
    markedWord: MarkWordMetadata | null,
    newText: string,
    marks: MarkWordMetadata[]
  ) => void;
  onReplaceAll?: (
    replaceInput: string,
    findInput: string,
    highlights: MarkWordMetadata[]
  ) => boolean;
  close: () => void;
  onBeforeUnmount?: () => void;
  onResetState?: () => void;
  mode: EditorMode;
  minimumFindInputLengthToTrigger: number;
}

export const FindAndReplace = ({
  triggerDecoration,
  onReplaceOne,
  onReplaceAll,
  close,
  onBeforeUnmount,
  afterTriggerDecorations,
  onResetState,
  mode,
  minimumFindInputLengthToTrigger,
}: FindAndReplaceProps) => {
  const { t } = useTranslation();
  const editor = useSlateStatic() as CustomEditor;
  const disabled = useRecoilValue(isDisabledState);
  const [findInputDirty, setFindInputDirty] = useState(false);
  const [findValueInEditor, setFindValueInEditor] = useState("");
  const [find, setFind] = useState("");
  const [replace, setReplace] = useState("");
  const [highlights, setHighlights] = useState<MarkWordMetadata[] | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const isLoading = useRef(false);
  const lastInputSearched = useRef("");

  const isSubtitles = useMemo(() => mode === "subtitles", [mode]);

  const selectedHighlight = useMemo(() => {
    if (selectedIndex !== null && highlights?.length) {
      return highlights[selectedIndex];
    }
    return null;
  }, [highlights, selectedIndex]);

  const replaceOneDisabled = useMemo(
    () => !selectedHighlight || !replace?.length || disabled,
    [selectedHighlight, replace?.length, disabled]
  );
  const replaceAllDisabled = useMemo(
    () => !replace?.length || !highlights?.length || disabled,
    [disabled, highlights?.length, replace?.length]
  );

  const handleHighlightClick = useCallback(
    (word: MarkWordMetadata, index: number) => {
      setSelectedIndex(index);
      EditorService.clearClassname("highlightFocused");
      EditorService.scrollAndHighlightWord({
        search: find.trim(),
        editor,
        word,
      });
    },
    [editor, find]
  );

  const handleTriggerDecoration = useCallback(
    (val: string) => {
      if (triggerDecoration) triggerDecoration(val);
      setFindValueInEditor(val);
    },

    [triggerDecoration, highlights]
  );

  const handleOnMouseLeaveHighlight = useCallback(() => {
    EditorService.clearClassname("highlightFocused");
    if (_.isNumber(selectedIndex) && highlights?.length) {
      EditorService.scrollAndHighlightWord({
        search: find.trim(),
        editor,
        word: highlights[selectedIndex],
      });
    }
  }, [editor, highlights, selectedIndex, find]);

  const onFindInputDirty = useCallback(() => {
    setFindInputDirty(true);
    setHighlights(null);
    EditorService.clearClassname("highlightFocused");
  }, [setFindInputDirty]);

  const updateHighlights = useCallback(
    (val: string, updateState = true) => {
      const nodes = Array.from(editor.nodes({ at: [] }));
      const ranges: MarkWordMetadata[] = [];
      nodes.forEach(([node, path]) => {
        const nodeHighlights = EditorService.getHighlightWordsByText({
          search: val,
          node: node as CustomElement,
          path,
        });
        ranges.push(...nodeHighlights);
      });

      if (updateState) setHighlights(ranges);
      return ranges;
    },
    [editor]
  );

  const calculateHighlights = useCallback(
    (val: string, focus = true) => {
      if (val.length < minimumFindInputLengthToTrigger) {
        onFindInputDirty();
        return;
      }

      if (findInputDirty) setFindInputDirty(false);
      handleTriggerDecoration(val);

      const ranges = updateHighlights(val);

      const wordToHighlight = ranges?.[0];
      if (wordToHighlight && focus) {
        setSelectedIndex(0);
        freeze(350).then(() => {
          // Bad practice, but we are waiting for the editor to paint
          EditorService.scrollAndHighlightWord({
            search: find.trim(),
            editor,
            word: wordToHighlight,
          });
        });
      } else {
        EditorService.clearClassname("highlightFocused");
      }

      afterTriggerDecorations && afterTriggerDecorations(find.trim(), ranges);

      return ranges;
    },
    [
      findInputDirty,
      handleTriggerDecoration,
      updateHighlights,
      afterTriggerDecorations,
      find,
      onFindInputDirty,
      editor,
    ]
  );

  const handleOnFindChange = useCallback((e: any) => {
    const val = e.target?.value || "";

    setFind(val);
  }, []);
  const handleOnReplaceChange = useCallback(
    (e: any) => {
      const val = e.target?.value || "";
      setReplace(val);
    },
    [setReplace]
  );

  const resetState = useCallback(() => {
    setSelectedIndex(null);
    EditorService.clearClassname("highlightFocused");
    if (onResetState) onResetState();
    if (triggerDecoration) triggerDecoration("");
    setHighlights(null);
    lastInputSearched.current = "";
  }, [setSelectedIndex, triggerDecoration, onResetState]);

  const handleOnHighlightItemHover = useCallback(
    (word?: MarkWordMetadata, shouldScroll?: boolean) => {
      EditorService.scrollAndHighlightWord({
        search: find.trim(),
        editor,
        word,
        shouldScroll,
      });
    },
    [editor, find]
  );

  const getHighlightsBeforeReplace = useCallback(() => {
    // We want to "refresh" the highlights before subtitles editor replacing
    const updatedHighlights = isSubtitles
      ? updateHighlights(find, false)
      : highlights;
    return updatedHighlights;
  }, [isSubtitles, updateHighlights, highlights, find]);

  const handleOnReplaceOne = useCallback(
    async (_selectedIndex?: number) => {
      isLoading.current = true;
      EditorService.clearClassname("highlightFocused");
      const idxToReplace = _.isNumber(_selectedIndex)
        ? _selectedIndex
        : selectedIndex;

      const highlights = getHighlightsBeforeReplace();

      if (!_.isNumber(idxToReplace) || !highlights) return;

      const newSelected = highlights[idxToReplace];

      onReplaceOne(newSelected, replace, highlights);

      resetState();

      const newHighlights =
        calculateHighlights(find?.trim() || "", false) || [];
      setSelectedIndex(idxToReplace);

      await freeze(1); // force new frame
      handleOnHighlightItemHover(newHighlights[idxToReplace]);

      await freeze(350);
      isLoading.current = false;
    },
    [
      getHighlightsBeforeReplace,
      selectedIndex,
      onReplaceOne,
      replace,
      resetState,
      calculateHighlights,
      find,
      handleOnHighlightItemHover,
    ]
  );

  const handleOnReplaceAll = useCallback(() => {
    if (!highlights) return;
    isLoading.current = true;
    const updatedHighlights = isSubtitles
      ? updateHighlights(find, false)
      : highlights;

    if (onReplaceAll) {
      const success = onReplaceAll(replace, find?.trim(), updatedHighlights);
      if (success) resetState();
    } else if (isSubtitles) {
      // In subtitles, we want to replace all using replace one on each iteration
      // the reason is we are not using decoration, so replace one will re-calculate the
      // highlight position
      highlights.forEach(() => {
        handleOnReplaceOne(0);
      });
    }

    handleTriggerDecoration(find);

    isLoading.current = false;
  }, [
    highlights,
    isSubtitles,
    updateHighlights,
    find,
    onReplaceAll,
    handleTriggerDecoration,
    replace,
    resetState,
    handleOnReplaceOne,
  ]);

  useEffect(() => {
    return () => {
      if (onBeforeUnmount) onBeforeUnmount();
      resetState();
    };
  }, []);

  // useEffect(() => {
  // Currently it makes problems, commented for now
  //   // This use effect is listening to editor change in order to trigger highlighting
  //   const { apply } = editor;
  //
  //   editor.apply = (operation) => {
  //     apply(operation);
  //
  //     const changeTextOperations = [
  //       "insert_text",
  //       "remove_text",
  //       "split_node",
  //       "merge_node",
  //       "set_node",
  //     ];
  //     if (changeTextOperations.includes(operation.type) && !isLoading.current) {
  //       close();
  //     } else {
  //       calculateHighlights(find?.trim(), false);
  //     }
  //   };
  //
  //   return () => {
  //     editor.apply = apply;
  //   };
  // }, [calculateHighlights, close, editor, find, isLoading]);

  const handleCalculateHighlights = useCallback(() => {
    if (isSubtitles) resetState();
    if (lastInputSearched.current === find?.trim()) return;
    lastInputSearched.current = find?.trim();
    calculateHighlights(find?.trim());
  }, [isSubtitles, calculateHighlights, find, lastInputSearched, resetState]);

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        handleCalculateHighlights();
      }
    },
    [handleCalculateHighlights]
  );

  return (
    <Box className={"FindAndReplace"} onKeyDown={onKeyDown}>
      <Box className={"inputsAndActions"}>
        <FindAndReplaceInputs
          find={find}
          findInputDirty={findInputDirty}
          onFindChange={handleOnFindChange}
          replace={replace}
          onReplaceChange={handleOnReplaceChange}
          minimumFindInputLengthToTrigger={minimumFindInputLengthToTrigger}
        />
        <Box className={"actionsContainer"}>
          <Button
            className={"searchButton"}
            onClick={handleCalculateHighlights}
            disabled={find?.trim()?.length < minimumFindInputLengthToTrigger}
          >
            {t("search")}
          </Button>
          <FindAndReplaceButtonsContainer
            isReplaceOneDisabled={replaceOneDisabled}
            onReplaceOne={handleOnReplaceOne}
            isReplaceAllDisabled={replaceAllDisabled}
            onReplaceAll={handleOnReplaceAll}
          />
        </Box>
      </Box>
      <Box onMouseLeave={handleOnMouseLeaveHighlight}>
        <FindAndReplaceHighlightsList
          selectedIndex={selectedIndex}
          highlights={highlights}
          find={findValueInEditor}
          selectedHighlight={selectedHighlight}
          onClick={handleHighlightClick}
          onItemHover={handleOnHighlightItemHover}
        />
      </Box>
    </Box>
  );
};
