import config from "../../config";
import classNames from "classnames";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { faArrowDownToLine } from "@fortawesome/pro-light-svg-icons";

import {
  BadgeWrapper,
  ExtractMediaPlayer,
  ExtractQueryBuilder,
  GeneralTable,
  HeadCell,
  SpinningLoader,
  TableCell,
  TableContext,
  TagsModal,
} from "@sumit-platforms/ui-bazar";

import {
  ExportedFile,
  ExtractQuery,
  ExtractResult,
  IndexType,
  Portrait,
  Tag,
} from "@sumit-platforms/types";
import { useAuth, useExtract, useTags } from "@sumit-platforms/ui-bazar/hooks";
import { useAlert, useModal } from "@sumit-platforms/ui-bazar/store";
import {
  clearWord,
  getTimecodeFromSeconds,
} from "@sumit-platforms/ui-bazar/utils";
import {
  exportService as ExportService,
  ExtractService,
} from "@sumit-platforms/ui-bazar/services";

import useSpeakers from "../../hooks/useSpeakers";

import { ExtractExportModal } from "../../components/modals/ExtractExportModal/ExtractExportModal";
import tagService from "../../services/tagService";
import jobService from "../../services/jobService";
import { clientStore } from "../../store/client";

import "./Extract.scss";

const exportService = ExportService({ config });

export interface JobRow {
  id: string;
  name: string;
  lang: string;
  tags: Tag[];
  tc: number;
  speakers: string[];
  transcript: { speaker: string; text: string }[];
  contextMenu: any;
  originalResult: ExtractResult;
  thumbnail: string;
}

export const EXTRACT_PAGE_DATE_FORMAT = "dd/MM/yyyy";

const extractService = ExtractService({ config });

const Extract2 = () => {
  const { client } = clientStore();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { fields: inputFields, setFields } = useExtract({
    extractService,
    client,
  });

  const page = useRef(1);
  const hasMore = useRef(false);

  const { user } = useAuth({ config });
  const { tags } = useTags({ entity: "job", tagService });
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<string | null>(
    null
  );
  const [tagNames, setTagNames] = useState<string[]>([]);
  const [speakerNames, setSpeakerNames] = useState<string[]>([]);
  const [ratingMap, setRatingMap] = useState<
    Record<string, { value: number; disabled: boolean }>
  >({});
  const [lastestExtractQuery, setLastestExtractQuery] = useState<ExtractQuery>(
    []
  );

  const searchQuery = useMemo<string>(() => {
    let _searchQuery = "";
    lastestExtractQuery?.map((segment) => {
      segment?.map((field) => {
        if (_.isString(field.value)) {
          _searchQuery = `${_searchQuery} ${field.value}`;
        }
      });
    });
    return _searchQuery;
  }, [lastestExtractQuery]);

  const { speakers } = useSpeakers({
    user,
  });
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const [loadingJobs, setLoadingJobs] = useState<boolean>(false);

  const [extractResults, setExtractResults] = useState<JobRow[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const selectedRows = useMemo(() => {
    return extractResults.filter((j) =>
      selected.includes(j.originalResult.resultId)
    );
  }, [selected, extractResults]);

  const portraits = useMemo<Portrait[]>(() => {
    return speakers
      .filter((speaker) => speaker.portrait)
      .map((speaker) => _.pick(speaker, ["portrait", "name"]));
  }, [speakers]);

  useEffect(() => {
    hasMore.current = false;
    setFields(null);
    setExtractResults([]);
    setCurrentPlayingIndex(null);
    setLastestExtractQuery([]);
    if (!client?.idClient) return;
    getTags(client.idClient);
    getSpeakers(client.idClient);
  }, [client]);

  async function getSpeakers(idClient: number) {
    try {
      const _speakers = await extractService.getClientMetadata(
        idClient,
        "speaker.keyword"
      );
      setSpeakerNames(_speakers);
    } catch (err) {
      console.error(err);
    }
  }

  async function getTags(idClient: number) {
    try {
      const _tags = await extractService.getClientMetadata(
        idClient,
        "tags.keyword"
      );
      setTagNames(_tags);
    } catch (err) {
      console.error(err);
    }
  }

  const setTags = async (idJob: number[], tags: Tag[]): Promise<void> => {
    try {
      await jobService.setTags(
        idJob,
        tags.map((t) => t.idTag)
      );
      setExtractResults((prev) =>
        prev.map((j) =>
          idJob.includes(j.originalResult.idJob) ? { ...j, tags } : j
        )
      );
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  const openTagsModal = (rows: JobRow[], tags: Tag[]): void => {
    if (client)
      setModalContent(
        <TagsModal
          entity="job"
          submit={(_tags) =>
            setTags(
              rows.map((r) => r.originalResult.idJob),
              _tags
            )
          }
          cancel={closeModal}
          tags={tags}
          idClient={client?.idClient}
          tagService={tagService}
        />
      );
  };

  const handleRatingChange = async (value: number | null, row: JobRow) => {
    try {
      if (!value || !row.originalResult.queryId) return;
      setAlert({ message: t("rating_sent"), severity: "info" });
      setRatingMap((prev) => {
        const newRatingMap = { ...prev };
        newRatingMap[row.id] = {
          value,
          disabled: true,
        };
        return newRatingMap;
      });
      await extractService.extractQueryFeedback({
        queryId: row.originalResult.queryId!,
        resultId: row.id,
        rating: value!,
      });
      setAlert({ message: t("rating_success"), severity: "success" });
    } catch (err) {
      setRatingMap((prev) => {
        const newRatingMap = { ...prev };
        newRatingMap[row.id] = {
          value: 0,
          disabled: false,
        };
        return newRatingMap;
      });
      console.error(err);
      setAlert({ message: t("rating_failed"), severity: "error" });
    }
  };

  const prepareExtractResultsForExport = (extractResults: ExtractResult[]) => {
    return extractResults.map((extractResult) => ({
      id_job: extractResult.idJob,
      name: extractResult.name,
      job_type: "",
      id_client: client?.idClient || 0,
      client_name: client?.name || "",
      created_at: new Date(),
      input_lang: "he-IL",
      output_lang: "he-IL",
      interval_index: 0,
      id_job_interval_index: extractResult?.resultId,
      speaker: extractResult?.speaker || "",
      start_time: 0,
      tc: extractResult?.timecode,
      text: extractResult?.transcript,
    }));
  };

  async function handleExportExtract(
    fileName: string,
    singleResult?: ExtractResult[]
  ) {
    try {
      const rowsToExport = singleResult
        ? singleResult
        : selectedRows.map((r) => r.originalResult);
      const docx = await exportService.createExtract(
        prepareExtractResultsForExport(rowsToExport),
        ""
      );
      const filesToExport: ExportedFile[] = [
        { filename: `${fileName}.docx`, data: docx[0] },
      ];
      await exportService.handleExportedFiles(filesToExport);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  }

  async function runExtractQuery(
    extractQuery: ExtractQuery
  ): Promise<ExtractResult[] | undefined> {
    if (!client?.idClient) return;
    try {
      setLoadingJobs(true);
      setCurrentPlayingIndex(null);
      setLastestExtractQuery(extractQuery);
      const extractResults = await extractService.runExtractQuery({
        extractQuery,
        idClient: client?.idClient,
        page: page.current,
      });

      if (extractResults.length === 0) {
        hasMore.current = false;
      } else {
        hasMore.current = true;
      }

      return extractResults;
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingJobs(false);
    }
  }

  const renderRowContext = (row: JobRow) => {
    return (
      <TableContext
        context={[
          {
            name: t("export_job"),
            action: () => openExportJobModal([row.originalResult]),
          },
        ]}
        row={row}
      />
    );
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openExportJobModal = (singleResult?: ExtractResult[]): void => {
    setModalContent(
      <ExtractExportModal
        confirm={(fileName) => handleExportExtract(fileName, singleResult)}
        cancel={closeModal}
      />
    );
  };

  const headCells: HeadCell<JobRow>[] = useMemo(() => {
    return [
      {
        id: "id",
        label: "",
        hideColumn: true,
      },
      {
        id: "thumbnail",
        label: t("finding"),
        style: { width: "20%" },
        barColActions: [
          {
            name: "export",
            icon: faArrowDownToLine,
            action: () => openExportJobModal(),
            disabled: selected?.length < 1,
          },
        ],
        formatter: (j: JobRow) => {
          return (
            <ExtractMediaPlayer
              extractResult={j.originalResult}
              setCurrentPlayingIndex={setCurrentPlayingIndex}
              currentPlayingIndex={currentPlayingIndex}
            />
          );
        },
      },
      {
        id: "transcript",
        label: "",
        style: { width: "50%" },
        showLabel: false,
        formatter: (j: JobRow) => (
          <div
            className={classNames("tableCell", {
              caption: j.originalResult.indexSearchType === IndexType.FRAMES,
            })}
          >
            <Box px={2}>
              {j.originalResult.indexSearchType === IndexType.FRAMES
                ? j.originalResult.text
                : transcriptTextFormatter(j)}
            </Box>
          </div>
        ),
      },
      {
        id: "tc",
        label: t("location_and_tags"),
        style: { width: "20%" },
        formatter: (j: JobRow) => {
          return (
            <Box display="flex" gap={1}>
              <Box>
                <TableCell primaryText={`${t("file")}:`} />
                <TableCell primaryText={`${t("tc")}:`} />
                <TableCell primaryText={`${t("tags")}:`} />
              </Box>
              <Box display={"inline-grid"}>
                <TableCell
                  ellipsisPosition={"top-start"}
                  primaryLink={`/job/${j.originalResult.idJob}`}
                  primaryText={j.originalResult.name}
                />
                <TableCell
                  primaryLink={`/job/${j.originalResult.idJob}?t=${Math.floor(
                    j.originalResult.start
                  )}`}
                  primaryText={getTimecodeFromSeconds(j.originalResult.start, {
                    tcOffsets: j.originalResult?.tcOffsets,
                  })}
                />
                <div
                  className="tagsWrapper"
                  onClick={() => {
                    openTagsModal([j], j.tags);
                  }}
                >
                  <TableCell
                    primaryText={
                      _.isEmpty(j.tags)
                        ? `${t("add_tags")}+`
                        : j.tags.map((r) => r.name).join(", ")
                    }
                  />
                </div>
              </Box>
            </Box>
          );
        },
      },
      {
        id: "contextMenu",
        sortable: false,
        style: { width: "9%" },
        label: "",
        formatter: renderRowContext,
      },
    ];
  }, [currentPlayingIndex, extractResults, selected]);

  const createJobRow = useCallback(
    (result: ExtractResult): JobRow => {
      return {
        id: result.resultId,
        name: "",
        lang: "",
        tags: tags.filter((t: Tag) => result.tags?.includes(t.name)),
        speakers: result.speakers || [],
        tc: result.timecode,
        transcript: result.transcript,
        contextMenu: null,
        originalResult: result,
        thumbnail: result.thumbnail || "",
      };
    },
    [tags]
  );

  const transcriptTextFormatter = useCallback(
    (j: JobRow) => {
      const searchQueryWords = searchQuery.split(/[ ,]+/).filter((w) => w);
      const formattedParagraphText = [];

      for (const speakerTranscript of j.transcript) {
        const formattedSpeakerText = [];
        const transcriptTextWords = speakerTranscript.text.split(" ");

        for (let i = 0; i < transcriptTextWords.length; i++) {
          const word = transcriptTextWords[i];
          if (
            _.some(
              _.map(
                searchQueryWords,
                (sqWord) =>
                  clearWord(sqWord) === clearWord(word) ||
                  clearWord(word).includes(clearWord(sqWord))
              )
            )
          ) {
            formattedSpeakerText.push(<b key={i}>{`${word} `}</b>);
          } else {
            formattedSpeakerText.push(<span key={i}>{`${word} `}</span>);
          }
        }

        formattedParagraphText.push(
          // TODO: indicate which language is the job to use 'rtl' or 'ltr'
          <div dir={"rtl"}>
            {speakerTranscript.speaker && (
              <u style={{ marginInlineEnd: 5 }}>
                {_.isArray(speakerTranscript.speaker)
                  ? speakerTranscript.speaker.join(", ")
                  : speakerTranscript.speaker}
                :
              </u>
            )}
            <span>{formattedSpeakerText}</span>
          </div>
        );
      }

      return <div className={"paragraphText"}>{formattedParagraphText}</div>;
    },
    [extractResults, searchQuery]
  );

  const handleOnLoadMore = useCallback(async () => {
    if (_.isEmpty(lastestExtractQuery)) return;
    page.current = page.current + 1;
    const extractResults = await runExtractQuery(lastestExtractQuery);
    if (!extractResults) return;
    setExtractResults((prevResults) => {
      const newResults = extractResults.map((extractResult) => {
        return createJobRow(extractResult);
      });
      return [...prevResults, ...newResults];
    });
  }, [lastestExtractQuery, client]);

  const handleOnSearchButton = useCallback(
    async (extractQuery: ExtractQuery) => {
      setExtractResults([]);
      hasMore.current = true;
      page.current = 1;
      const extractResults = await runExtractQuery(extractQuery);
      if (!extractResults) return;
      setExtractResults(
        extractResults.map((extractResult) => createJobRow(extractResult))
      );
      setSelected([]);
    },
    [client, tags]
  );

  return (
    <Grid container className="Extract Page">
      <Grid container justifyContent={"center"} pb={4}>
        <Grid item xs={11} className="titleWrapper">
          <BadgeWrapper badgeContent={t("beta")} className="badgeTitle">
            <p className="pageTitle">{`${t("extract")} 3.0`}</p>
          </BadgeWrapper>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11}>
          {inputFields ? (
            inputFields.length > 0 && (
              <ExtractQueryBuilder
                portraits={portraits}
                speakers={speakerNames}
                tags={tagNames}
                isLoading={loadingJobs}
                onSearch={handleOnSearchButton}
                inputFields={inputFields}
              />
            )
          ) : (
            <Box maxWidth={"25px"} pb={5}>
              <SpinningLoader />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} pb={1}>
        <Grid item xs={11} className="titleWrapper">
          <p className="pageSubTitle">
            {extractResults.length
              ? t("showing_results", { count: extractResults.length })
              : t("no_results")}
          </p>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11}>
          <GeneralTable
            headCells={headCells}
            rows={extractResults}
            selected={selected}
            setSelected={setSelected}
            loading={loadingJobs}
            onLoadMore={handleOnLoadMore}
            hasMore={hasMore.current}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Extract2;
