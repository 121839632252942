import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "@sumit-platforms/ui-bazar/hooks";
import config from "../config";

const Guest = () => {
  const navigate = useNavigate();
  const { user } = useAuth({ config });

  useEffect(() => {
    if (user?.roles) {
      console.log("HERE");
      navigate("/");
    }
  }, [user?.roles]);

  return <Outlet />;
};

export default Guest;
