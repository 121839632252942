import classNames from "classnames";
import React from "react";

const TextWithBreaks = ({
  text,
  bold,
  dir,
  className,
}: {
  text: string;
  bold: boolean;
  dir: string;
  className?: string;
}) => {
  // Replace '\n' with <br /> tags
  const formattedText = text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <p className={classNames(`${className || ""} ${dir}`, { bold })}>
      {formattedText}
    </p>
  );
};

export default TextWithBreaks;
