import React, { useEffect, useMemo } from "react";
import config from "./config";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import authService from "./services/authService";
import {
  useFeatureFlag,
  useKeyboardShortcuts,
  useProjectsSettings,
  useAuth,
  useMFA,
} from "@sumit-platforms/ui-bazar/hooks";

import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import {
  BreathingLoader,
  ErrorPage,
  PrivateRoute,
} from "@sumit-platforms/ui-bazar";

//PAGES
import Dashboard from "./pages/Dashboard";
import Guest from "./pages/Guest";
import Job from "./pages/Job/Job";
import UploadFiles from "./pages/UploadFiles/UploadFiles";
import Deliveries from "./pages/Deliveries/Deliveries";
import Extract from "./pages/Extract/Extract";

import PreLoginPage from "./pages/Auth/PreLoginPage";
import SignUpPage from "./pages/Auth/SignUpPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import NotFound from "./pages/NotFound/NotFound";
import Extract2 from "./pages/Extract/Extract2";
import { useTranslation } from "react-i18next";
import { faBan } from "@fortawesome/pro-light-svg-icons";
import Projects from "./pages/Projects/Projects";
import { ProjectSettingsEditor } from "./pages/ProjectSettingsEditor/ProjectSettingsEditor";
import {
  MFAPage,
  VerifyOTPCode,
  VerifySMSCode,
} from "@sumit-platforms/ui-bazar/pages";
import { MFAMethods } from "@sumit-platforms/types";
import { authMap } from "./layout/Dashboard/menuItems";

const B2B = () => {
  const { t } = useTranslation();
  const newExtract = useFeatureFlag("newExtract");
  const showProjectPageFF = useFeatureFlag("must_projectPage");
  const { user, roles, handleUserAuthentication, isLoading, signOut } = useAuth(
    {
      config,
      getUserDetails: authService.getUser,
    }
  );

  const {
    enrollSMS,
    enrollTOTP,
    verifySMSCode,
    validateMfaSession,
    verifyOTPCode,
    resendSMS,
  } = useMFA({
    config,
    onEnrollSuccess: (type, qrCode) => {
      console.log("onEnrollSuccess");
      handleUserAuthentication();
      router.navigate(`/mfa/${type}`, { state: { qrCode } });
    },
    onEnrollFail: (error, type) => {
      console.log("onEnrollFail");
      if (error === "already_enrolled") {
        handleUserAuthentication();
        router.navigate(`/mfa/${type}`);
      }
    },
    onVerificationSuccess: () => {
      console.log("onVerificationSuccess");
      handleUserAuthentication();
      router.navigate("/");
    },
  });

  const { initShortcuts } = useKeyboardShortcuts();

  useEffect(() => {
    initShortcuts(user?.settings);
  }, [initShortcuts, user?.settings]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "",
          element: (
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap["/"]}
            // >
            <Deliveries />
            // </PrivateRoute>
          ),
        },
        {
          path: "/upload-files",
          element: (
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap["upload-files"]}
            // >
            <UploadFiles />
            // </PrivateRoute>
          ),
        },
        {
          path: "/extract",
          element: (
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap.extract}
            // >
            <Extract />
            // </PrivateRoute>
          ),
        },
        {
          path: "/extract2",
          element: newExtract ? (
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap.extract2}
            // >
            <Extract2 />
          ) : (
            // </PrivateRoute>
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap.extract}
            // >
            <Extract />
            // </PrivateRoute>
          ),
        },
        {
          path: "/job/:idJob",
          element: <Job />,
        },
        {
          path: "unauthorized",
          element: (
            <ErrorPage
              title={t("unauthorized")}
              text={t("unauthorized_text")}
              icon={faBan}
              redirectToOnClick={"/"}
            />
          ),
        },
        {
          path: "/projects/",
          element: (
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap.projects}
            // >
            <Projects />
            // </PrivateRoute>
          ),
        },
        {
          path: "projects/:idProject",
          element: (
            // <PrivateRoute
            //   userStatus={user?.status}
            //   userRoles={roles}
            //   authConfig={authMap.projects}
            // >
            <ProjectSettingsEditor useSettings={useProjectsSettings} />
            // </PrivateRoute>
          ),
        },
        {
          path: "/*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/",
      element: <Guest />,
      children: [
        {
          path: "signup",
          element: <SignUpPage />,
        },
        {
          path: "login",
          element: <PreLoginPage />,
        },
        {
          path: "password-reset",
          element: <ResetPasswordPage />,
        },
        {
          path: "mfa",
          element: (
            <MFAPage
              enrollSMS={enrollSMS}
              validateMfaSession={validateMfaSession}
              enrollTOTP={enrollTOTP}
            />
          ),
        },
        {
          path: "mfa/sms",
          element: (
            <VerifySMSCode
              verifySMSCode={verifySMSCode}
              resendCode={resendSMS}
              logOut={signOut}
              validateMfaSession={validateMfaSession}
            />
          ),
        },
        {
          path: "mfa/totp",
          element: (
            <VerifyOTPCode
              verifyOTPCode={verifyOTPCode}
              type={MFAMethods.totp}
              validateMfaSession={validateMfaSession}
              logOut={signOut}
            />
          ),
        },
        {
          path: "*",
          element: <PreLoginPage />,
        },
      ],
    },
  ]);

  return isLoading ? (
    <BreathingLoader
      className={isLoading ? "fadeIn" : "fadeOut"}
      image={sumitLogoBlack}
    />
  ) : (
    <RouterProvider router={router} />
  );
};

export default B2B;
