import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AuthMapItem, UserRole, UserStatus } from "@sumit-platforms/types";
import { isAuthorized } from "../../utils/authentication";

export const PrivateRoute = React.memo(
  ({
    children,
    authConfig,
    fallbackRoute,
    userRoles,
    userStatus,
  }: {
    children: JSX.Element;
    authConfig?: AuthMapItem;
    fallbackRoute?: string;
    userRoles: UserRole[];
    userStatus?: UserStatus;
  }) => {
    const isAllowed = isAuthorized({ authConfig, userRoles, userStatus });
    console.log("isAllowed :", isAllowed);

    if (isAllowed) {
      return children;
    } else {
      if (fallbackRoute) {
        return <Navigate to={fallbackRoute} />;
      }
      return <Navigate to="/unauthorized" />;
    }
  }
);
