import React from "react";
import config from "../../config";
import { Box, Grid } from "@mui/material";
import {
  Button,
  UploadFilesModal,
  UploadFilesTable,
} from "@sumit-platforms/ui-bazar";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import { useTranslation } from "react-i18next";
import authService from "../../services/authService";
import { useAuth, useToast, useUploads } from "@sumit-platforms/ui-bazar/hooks";
import { useGlobalData } from "../../store";
import { clientStore } from "../../store/client";
import tagService from "../../services/tagService";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import { Client } from "@sumit-platforms/types";

import "./UploadFiles.scss";

const UploadFiles = () => {
  const { t } = useTranslation() as any;
  const { user } = useAuth({ config });
  const { setToast } = useGlobalData();
  const { client } = clientStore();
  const { toastError } = useToast({ setToast });
  const { setModalContent, setModalType, clearModalContent } = useModal();

  const { uploads, handleCreateUpload } = useUploads({
    config,
    user,
    idClient: client?.idClient,
  });

  const handleOnUpload = ({
    files,
    attachedFiles,
    googleDriveToken,
  }: {
    files: File[] | CallbackDoc[];
    attachedFiles?: Record<string, File[]>;
    googleDriveToken?: string;
  }) => {
    handleCreateUpload({
      files,
      attachedFiles,
      googleDriveToken,
      // getToken: authService.getToken,
      onFail: () => toastError(t("upload_failed")),
      client: client as Client,
    });
  };

  const openUploadFilesModal = (): void => {
    setModalContent(
      <UploadFilesModal
        firebase={config.firebase}
        closeModal={closeModal}
        onUpload={handleOnUpload}
      />
    );
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  return (
    <Grid
      className="UploadFiles Page"
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("upload_files")}</h1>
      </Grid>
      <Grid item xs={11} mb={3}>
        <Box className="box">
          <div className="uploadFilesPriceWrapper">
            <div className="secondLine">
              <span>{`${uploads.length} ${t("uploads")}`}</span>
            </div>
          </div>
          <div className="uploadFilesButtonWrapper">
            <Button
              children={t("upload_files")}
              onClick={openUploadFilesModal}
              className={"light"}
              disabled={!client}
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={11} mb={3}>
        <UploadFilesTable
          config={config}
          user={user}
          selectedClient={client}
          setToast={setToast}
          tagService={tagService}
          featureFlags={{
            skipStt: false,
          }}
          disableSubmit={client?.disabled}
        />
      </Grid>
    </Grid>
  );
};

export default UploadFiles;
