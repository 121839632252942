import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  ReactEditor,
} from "@sumit-platforms/slate-react";
import { Path } from "@sumit-platforms/slate";
import EditorService from "./services/EditorService";
import { Job } from "@sumit-platforms/types";
import {
  AnnotationRangeElement,
  CustomEditor,
  CustomElement,
  MarkWordMetadata,
  SpeakerRangeElement,
} from "./types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  currentTimeState,
  featureFlagsState,
  isDisabledState,
  isFindAndReplaceOpenState,
  karaokeState,
  speakersState,
} from "./store/states";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FindAndReplace } from "./components/FindAndReplace/FindAndReplace";
import JobSpeakersPanel from "./components/JobSpeakers/JobSpeakersComponent";
import { EditableProps } from "@sumit-platforms/slate-react";
import { EditorForwardedRef } from "./SlateEditor";
import { ActionElement } from "./components/ActionElement/ActionElement";

import "./TranscriptEditor.scss";

interface TranscriptEditorProps {
  editorController: CustomEditor;
  job: Job;
  className?: string;
  renderElement: (props: RenderElementProps) => JSX.Element;
  renderLeaf: (props: RenderLeafProps) => JSX.Element;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleBlur: () => void;
  handleReadOnlyClick: () => void;
}

const TranscriptEditor = (
  {
    editorController,
    job,
    renderElement,
    renderLeaf,
    onKeyDown,
    className,
    handleBlur,
    handleReadOnlyClick,
  }: TranscriptEditorProps,
  ref: React.Ref<EditorForwardedRef>
) => {
  const featureFlags = useRecoilValue(featureFlagsState);
  const isDisabled = useRecoilValue(isDisabledState);
  const time = useRecoilValue(currentTimeState); // time will update only if readonly and karaoke mode is active
  const karaokeMode = useRecoilValue(karaokeState);
  const setSpeakers = useSetRecoilState(speakersState);
  const [highlightSearchInput, setHighlightSearchInput] = useState("");
  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useRecoilState(
    isFindAndReplaceOpenState
  );

  const editorRef = useRef<HTMLElement | null>(null);
  const lastFindAndReplaceTerm = useRef("");

  const onFindAndReplaceInput = useCallback(setHighlightSearchInput, [
    setHighlightSearchInput,
  ]);

  const onReplaceOne = useCallback(
    (markedWord: MarkWordMetadata | null, newText: string) =>
      EditorService.replaceOne({
        editor: editorController,
        markedWord,
        newText,
        mode: "transcript",
      }),

    [editorController]
  );
  const onReplaceAll = useCallback(
    (
      replaceInput: string,
      findInput: string,
      highlights: MarkWordMetadata[]
    ) => {
      return EditorService.replaceAll({
        editor: editorController,
        replaceInput,
        highlights,
        findInput,
        mode: "transcript",
      });
    },
    [editorController]
  );

  const createAnnotation = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      EditorService.createSlateAnnotation(editorController);
    },
    [editorController]
  );

  const handleOpenSpeakersComponentKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      EditorService.handleOpenSpeakers(editorController);
    },
    [editorController]
  );

  const handleLockModeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      handleReadOnlyClick();
    },
    [handleReadOnlyClick]
  );

  const actionSectionElement = useMemo(() => {
    if (isFindAndReplaceOpen) {
      return (
        <FindAndReplace
          triggerDecoration={onFindAndReplaceInput}
          onReplaceOne={onReplaceOne}
          onReplaceAll={onReplaceAll}
          onBeforeUnmount={() => (lastFindAndReplaceTerm.current = "")}
          close={() => setIsFindAndReplaceOpen(false)}
          mode={"transcript"}
          minimumFindInputLengthToTrigger={3}
        />
      );
    }
    return null;
  }, [
    isFindAndReplaceOpen,
    onFindAndReplaceInput,
    onReplaceAll,
    onReplaceOne,
    setIsFindAndReplaceOpen,
  ]);

  useImperativeHandle(ref, () => ({
    editorRef: editorRef.current,
    lastFindAndReplaceTerm: lastFindAndReplaceTerm.current,
  }));

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      EditorService.focusToNextSpeakerTextRange(editorController);
    },
    [editorController]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      EditorService.focusToPrevSpeakerTextRange(editorController);
    },
    [editorController]
  );

  useKeyboardShortcuts({
    handlers: {
      CREATE_NEW_ANNOTATION: createAnnotation,
      OPEN_SPEAKERS: handleOpenSpeakersComponentKeyStroke,
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
    },
    ref: editorRef.current,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  useKeyboardShortcuts({
    handlers: {
      LOCK_MODE: handleLockModeKeystroke,
    },
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  const handleDecorateByTime = useCallback(
    ([node, path]: [CustomElement, Path]) => {
      const ranges = EditorService.getHighlightWords({
        currentTime: time,
        node: node as SpeakerRangeElement | AnnotationRangeElement,
        path,
        search: highlightSearchInput,
      });

      return ranges;
    },
    [highlightSearchInput, time]
  );

  const handleDecorateByText = useCallback(
    ([node, path]: [CustomElement, Path]) => {
      const nodeHighlights = EditorService.getHighlightWordsByText({
        search: highlightSearchInput,
        node,
        path,
      });
      lastFindAndReplaceTerm.current = highlightSearchInput;
      return nodeHighlights;
    },
    [highlightSearchInput]
  );

  const getDecorate = useCallback(() => {
    const searchDecoration = !!(
      featureFlags.findAndReplace &&
      isFindAndReplaceOpen &&
      highlightSearchInput?.length &&
      lastFindAndReplaceTerm.current.trim() !== highlightSearchInput.trim()
    );

    const timeDecoration =
      karaokeMode && !searchDecoration && featureFlags.karaoke;

    if (searchDecoration) {
      return handleDecorateByText as EditableProps["decorate"];
    }

    if (timeDecoration) {
      return handleDecorateByTime as EditableProps["decorate"];
    }

    return;
  }, [
    featureFlags,
    isFindAndReplaceOpen,
    highlightSearchInput,
    karaokeMode,
    handleDecorateByText,
    handleDecorateByTime,
  ]);

  const updateEditorMode = useCallback(() => {
    if (editorController.mode) return;
    editorController.mode = "transcript";
  }, [editorController]);

  useEffect(() => {
    if (!editorController) return;
    updateEditorMode();
    try {
      const _editorRef = ReactEditor.toDOMNode(
        editorController,
        editorController
      );
      editorRef.current = _editorRef;

      setSpeakers(EditorService.getSpeakers(editorController));
    } catch (e) {
      console.error("e :", e);
    }
  }, [editorController, setSpeakers]);

  return (
    <>
      <JobSpeakersPanel
        disabled={isDisabled}
        job={job}
        editorRef={editorRef.current}
      />
      <ActionElement hide={!actionSectionElement}>
        {actionSectionElement}
      </ActionElement>
      <Editable
        className={className}
        readOnly={isDisabled}
        renderLeaf={renderLeaf}
        decorate={getDecorate()}
        onBlur={() => handleBlur()}
        renderElement={renderElement}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

export default forwardRef(TranscriptEditor);
