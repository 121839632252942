import { useEffect, useState } from "react";

export const useLazyLoad = (elementRef: any, enabled: boolean, rootEl: any) => {
  const [isIntersected, setIsIntersected] = useState(enabled ? false : true);

  useEffect(() => {
    if (!enabled || !rootEl) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsIntersected(entry.isIntersecting);
      },
      {
        root: rootEl,
        rootMargin: "2000px",
      }
    );

    if (elementRef && rootEl) {
      observer.observe(elementRef);
    }

    return () => {
      if (elementRef && rootEl) {
        observer.unobserve(elementRef);
      }
    };
  }, [elementRef, enabled, rootEl]);

  return { isIntersected };
};
